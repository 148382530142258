import { signInWithEmailAndPassword } from 'firebase/auth';
import React from 'react'
import { auth } from '../../../firebase-config';
import { LayoutFS } from '../../locust/composables/Layout';
import {Signin} from '../../locust/components/Signin'
import { navigate } from 'gatsby';

const SigninPage = () => {

    const [awaitingSignin, setAwaitingSignin] = React.useState(false)
    const [username, setUsername] = React.useState("")
    const [password, setPassword] = React.useState("")
    
    return (
        <>

            <LayoutFS min={350}>
                {/*<CobraHeader shoppingCart={Object.keys(getShoppingCart()).length}/>*/}
                <Signin
                    awaitingSignin={awaitingSignin}
                    username={username}
                    password={password}
                    setPassword={setPassword}
                    setUsername={setUsername}
                    onClick={()=>{
                        //alert(22)
                        //alert(username)
                        //alert(password)
                        //setAwaitingSignin(true)
                        signInWithEmailAndPassword(auth,username,password).then(()=>{
                            console.log('success!')
                            navigate('/admin')
                            console.log('true')
                        }).catch(( error : any )=>{
                            console.log(error)
                        })
                        /*
                        auth.onAuthStateChanged(()=>{
                            setAwaitingSignin(false)
                            setTimeout(()=>{
                                alert(222)
                            },1000)
                        })
                        */
                    }}
                />
            </LayoutFS>
            
        
        </>
    )
}

export default SigninPage;